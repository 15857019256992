<template>
  <div>
    <filter-view :loading="loading" @submit="doQuery">
      <div class="c h sb sep-t padding-05" @click="drawer.shop = true">
        <div class="no-flex padding-05">自定义区域</div>
        <div class="padding-05 flex ta-r">{{ label.shop }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h">
        <div class="no-flex padding-10">年度</div>
        <div class="flex"></div>
        <el-date-picker type="year" v-model="year" format="yyyy年" :clearable="false" @change="calcWeeks" style="width: 1.75rem;" />
        <i class="el-icon-arrow-right padding-10 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.weekBeg = true">
        <div class="no-flex padding-05">起始周</div>
        <div class="flex ta-r padding-05" v-if="query.weekBeg">{{ weekOptions[query.weekBeg].name }}</div>
        <div class="flex ta-r fc-g padding-05" v-else>请选择</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.weekEnd = true">
        <div class="no-flex padding-05">截止周</div>
        <div class="flex ta-r padding-05" v-if="query.weekEnd">{{ weekOptions[query.weekEnd].name }}</div>
        <div class="flex ta-r fc-g padding-05" v-else>请选择</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sb sep-t">
        <div class="no-flex padding-10">仓库类型</div>
        <el-checkbox-group v-model="query.warehouseTypes" @change="doQuery">
          <template v-for="t in warehouseTypes">
            <el-checkbox :key="t.id" :label="t.id">{{t.name}}</el-checkbox>
          </template>
        </el-checkbox-group>
      </div>
      <div class="c h sb sep-t">
        <div class="no-flex padding-10">商品大类</div>
        <el-checkbox-group v-model="query.goodsClassIds" @change="doQuery">
          <template v-for="t in goodsClasses">
            <el-checkbox :key="t.classId" :label="t.classId">{{t.className}}</el-checkbox>
          </template>
        </el-checkbox-group>
      </div>
    </filter-view>

    <shop-list :visible.sync="drawer.shop" v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="handleShopChange" @init="$emit(init)" />
    <customiz-select key="wb" :visible.sync="drawer.weekBeg" :options="weekOptions" v-model="query.weekBeg" @change="doQuery" />
    <customiz-select key="we" :visible.sync="drawer.weekEnd" :options="weekOptions" v-model="query.weekEnd" @change="doQuery" />

    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div class="padding-10-20 h m">
          <el-radio-group v-model="analyType" @change="drawChart">
            <el-radio-button label="quantity">库存</el-radio-button>
            <el-radio-button label="amount">金额</el-radio-button>
          </el-radio-group>
        </div>
        <div ref="chart" style="height:420px;"></div>
        <div class="fc-g ta-c padding-10" v-if="!chartStore || !chartStore.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <template v-if="data && data.length > 0">
        <div class="padding-10 fs-small fc-g h c sb">
          <span>数据列表</span>
          <span>{{total}}条数据</span>
        </div>
        <div class="round-panel bc-w">
          <el-alert :title="`以下是第${filterWeek}周的库存数据`" type="info" effect="dark" closable @close="doReinit" v-if="filterWeek" />
          <template v-for="d in data">
            <div class="padding-05 sep-t" :key="d.id">
              <div class="padding-05">{{d.year}}年 第{{d.week}}周</div>
              <div class="padding-05 bold">{{d.distributorName}}</div>
              <div class="h e">
                <div class="fc-g fs-mini lh-150 flex padding-05">
                  区域：{{d.areaName}}
                  <br />
                  仓库类型：{{warehouseTypes[d.warehouseType].name}}
                </div>
                <div class="padding-05 ta-c lh-150" style="width: 1.2rem">
                  <div class="fc-g">商品大类</div>
                  <div>{{d.goodsClassName}}</div>
                </div>
                <div class="padding-05 ta-c lh-150" style="width: 1.2rem">
                  <div class="fc-g">库存</div>
                  <div class="bold">{{$quantity(d.quantity)}}</div>
                </div>
                <div class="padding-05 ta-r lh-150" style="width: 1.8rem">
                  <div class="fc-g">金额</div>
                  <div class="bold">{{$price(d.amount)}}</div>
                </div>
              </div>
            </div>
          </template>
          <!-- <div class="padding-05 sep-b" v-for="o in pagedStore" :key="o.warehouseId">
            <div class="c h wrap fs-small lh-150">
              <div class="padding-05" style="width: 100%">
                <div>{{ o.warehouseName || "-" }}，数量{{ o.goodsCount || "-" }}</div>
              </div>
            </div>
          </div>-->
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="total" :current-page="page + 1" :page-size="size" layout="prev, pager, next" hide-on-single-page @current-change="pageChange" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import shopList from "../purchaseAll/shopList";
import request from "@/utils/request";
import initData from "@/mixins/initData";
import customizSelect from "../assembly/selector/customize";

export default {
  components: { filterView, shopList, customizSelect },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      weekOptions: [],
      goodsClasses: [],
      warehouseTypes: [
        { id: 0, name: "中央库房" },
        { id: 1, name: "零售店" },
      ],
      drawer: {
        shop: false,
        weekBeg: false,
        weekEnd: false,
      },
      label: {
        shop: "全部",
        weekBeg: null,
        weekEnd: null,
      },
      year: null,
      filterWeek: null,
      analyType: "quantity",
      query: {
        year: null,
        weekBeg: null,
        weekEnd: null,
        treeNodeId: null,
        treeNodeType: null,
        warehouseTypes: [],
        goodsClassIds: [],
      },
      chart: null,
      chartStore: [],
    };
  },

  methods: {
    handleShopChange(data) {
      this.label.shop = data ? data.name : "全部";
    },
    beforeInit() {
      this.url = "@host:analysis;api/historyStock";
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: "week,desc",
        },
        this.query
      );
      if (this.filterWeek) {
        this.params.week = this.filterWeek;
      }
      return true;
    },
    calcWeeks() {
      let d = this.year,
        maxWeek = new Date(d.getFullYear(), 11, 31).getWeek(),
        ws = [{ id: null, name: "不选择" }];
      let b, e;
      for (let i = 1; i <= maxWeek; i++) {
        if (i === 1) {
          b = new Date(d.getFullYear(), 0, 1);
          let _d = b.getDay();
          if (_d === 0) _d = 7;
          e = new Date(d.getFullYear(), 0, 8 - _d);
        } else {
          b = new Date(e.getFullYear(), e.getMonth(), e.getDate() + 1);
          if (i === maxWeek) {
            e = new Date(e.getFullYear(), 11, 31);
          } else {
            e = new Date(e.getFullYear(), e.getMonth(), e.getDate() + 7);
          }
        }
        ws.push({
          id: i,
          name: `第${i}周（${b.format("MM-dd")}至${e.format("MM-dd")}）`,
          label: `第${i}周`,
          range: `${b.format("MM-dd")}至${e.format("MM-dd")}`,
        });
      }
      this.weekOptions = ws;
    },
    doReinit() {
      this.filterWeek = null;
      this.toQuery();
    },
    doQuery() {
      if (this.year && this.query.treeNodeType && this.query.treeNodeId) {
        if (
          this.query.weekBeg &&
          this.query.weekEnd &&
          this.query.weekBeg > this.query.weekEnd
        ) {
          let b = this.query.weekBeg;
          this.query.weekBeg = this.query.weekEnd;
          this.query.weekEnd = b;
        }
        this.query.year = this.year.getFullYear();

        request({
          url: "@host:analysis;api/historyStock/byWeek",
          method: "get",
          params: this.query,
        }).then((res) => {
          res.forEach((d) => {
            d.quantity = Math.round(d.quantity * 100) / 100;
            d.amount = d.amount / 100;
          });
          this.chartStore = res;
          this.drawChart();
        });
      }
      this.filterWeek = null;
      this.page = 0;
      this.init();
    },
    drawChart() {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chart, "light", {
          height: 420,
        });
        this.chart.on("click", (e) => {
          let week = (e.name || e.value).replace("第", "").replace("周", "");
          if (week) {
            this.filterWeek = week;
            this.toQuery();
          }
        });
      }

      let xDatas = [],
        series = [],
        sobj = {},
        ts = {
          type: "bar",
          name: "合计",
          data: [],
          barMaxWidth: 10,
          barGap: "0%",
          itemStyle: {
            color: "rgba(0,0,0,.3)"
          }
        },
        tdo = {};
      (this.chartStore || []).forEach((d) => {
        if (!sobj.hasOwnProperty(d.goodsClassName)) {
          sobj[d.goodsClassName] = {
            type: "bar",
            stack: this.analyType,
            name: d.goodsClassName,
            data: [],
          };
          series.push(sobj[d.goodsClassName]);
        }
        let wn = "第" + d.week + "周";
        if (xDatas.indexOf(wn) < 0) xDatas.push(wn);
        if (!tdo.hasOwnProperty(wn)) {
          tdo[wn] = {
            name: wn,
            value: 0,
            week: d.week,
          };
          ts.data.push(tdo[wn]);
        }
        tdo[wn].value += d[this.analyType];

        sobj[d.goodsClassName].data.push({
          name: wn,
          value: d[this.analyType],
          week: d.week,
        });
      });
      for (let k in tdo) {
        if (this.analyType === "amount") {
          tdo[k].value = Math.round(tdo[k].value * 100) / 100;
        } else {
          tdo[k].value = Math.round(tdo[k].value);
        }
      }
      series.push(ts);

      this.chart.clear();
      this.chart.setOption({
        grid: {
          top: 10,
          right: 20,
          bottom: 20,
          left: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: xDatas,
          triggerEvent: true,
        },
        yAxis: {
          type: "value",
        },
        series,
      });
    },
  },
  mounted() {
    request({
      url: "api/goodsclass",
      method: "get",
    }).then((res) => {
      this.goodsClasses = res;
    });
    const now = new Date();
    this.year = now;
    this.calcWeeks();
    this.query.weekEnd = now.getWeek();
    let bw = this.query.weekEnd - 7;
    if (bw < 1) bw = 1;
    this.query.weekBeg = bw;

    this.doQuery();
  },
};
</script>